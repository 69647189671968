<template>
  <v-container>
    <app-error v-if="error" :error="error" />
    <app-loader v-if="!provider" large></app-loader>
    <div v-else>
      <v-row>
        <v-col cols="9" md="11">
          <h1>{{ provider.title }}</h1>
        </v-col>
        <v-col cols="3" md="1">
          <h6>
            <v-chip>{{ provider.status }}</v-chip>
          </h6>
        </v-col>
      </v-row>
      <v-divider class="mb-5" />
      <v-row>
        <v-col v-if="provider.subscription" cols="12" md="6">
          <subscription-card :subscription="provider.subscription" />
          <v-card class="mt-3">
            <v-card-title>{{ $t("serviceProviders.types") }}</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item v-for="type in provider.types" :key="type.uid">
                  {{ type.name }}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="mb-3">
            <v-card-text>
              <organization-selection
                :organization="organization"
                @change="organizationChanged"
              />
            </v-card-text>
          </v-card>

          <contact-card
            v-if="provider.subscription && provider.subscription.billing"
            :contact="provider.subscription.billing"
            :title="$t('contacts.sources.INVOICING')"
          />

          <contact-card
            v-if="provider.contact"
            :contact="provider.contact"
            :title="$t('serviceProviders.contact')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <analytics-card
            :subject="provider"
            :url="'/api/v1/serviceproviders/' + uid + '/analytics'"
          ></analytics-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import SubscriptionCard from "@/components/subscriptions/SubscriptionCard";
import ContactCard from "@/components/contacts/ContactCard";
import OrganizationSelection from "@/components/organizations/OrganizationSelection";
import AnalyticsCard from "@/components/analytics/AnalyticsCard";

export default {
  props: {
    uid: { type: String, required: true },
  },
  data() {
    return {
      provider: null,
      error: null,
    };
  },
  created() {
    this.$store
      .dispatch("serviceProviders/get", {
        uid: this.uid,
        includes: [
          "subscription",
          "subscription.billing",
          "customer",
          "contact",
        ],
      })
      .then((p) => (this.provider = p))
      .catch((err) => (this.error = err));
  },
  computed: {
    organization() {
      if (this.provider.organization) return this.provider.organization;

      if (this.provider.subscription)
        return this.provider.subscription.organization;

      return {};
    },
  },
  components: {
    AnalyticsCard,
    SubscriptionCard,
    ContactCard,
    OrganizationSelection,
  },
  methods: {
    organizationChanged(organization) {
      if (organization) {
        this.$store.dispatch("serviceProviders/setOrganization", {
          uid: this.provider.uid,
          organization,
        });
      } else {
        this.$store.dispatch(
          "serviceProviders/removeOrganization",
          this.provider.uid
        );
      }
    },
  },
};
</script>

<style></style>
