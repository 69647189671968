<template>
  <div class="d-flex">
    <v-icon>filter_list</v-icon>
    <div>
      <v-chip
        v-for="chip in chips"
        :key="chip.key + '.' + chip.index"
        close
        @click:close="chip.remove()"
        class="ml-3"
      >
        <span>{{ $t("serviceProviders.filters." + chip.key) }}:&#160;</span>
        <span v-if="chip.key === 'status'">
          {{ $t("subscriptions.status." + chip.value) }}
        </span>
        <span v-else-if="chip.key === 'type'">
          {{ typeItem(chip.value).name }}
        </span>
        <span v-else>{{ chip.value }}</span>
      </v-chip>
    </div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn fab small icon v-on="on">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="zip = true">
          {{ $t("serviceProviders.filters.zip") }}
        </v-list-item>
        <v-list-item @click="city = true">
          {{ $t("serviceProviders.filters.city") }}
        </v-list-item>
        <v-list-item @click="country = true">
          {{ $t("serviceProviders.filters.country") }}
        </v-list-item>
        <v-list-item @click="province = true">
          {{ $t("serviceProviders.filters.province") }}
        </v-list-item>
        <v-list-item @click="status = true">
          {{ $t("serviceProviders.filters.status") }}
        </v-list-item>
        <v-list-item @click="visibility = true">
          {{ $t("serviceProviders.filters.visibility") }}
        </v-list-item>
        <v-list-item @click="type = true">
          {{ $t("serviceProviders.filters.type") }}
        </v-list-item>
        <v-list-item @click="typeCount = true">
          {{ $t("serviceProviders.filters.typeCount") }}
        </v-list-item>
      </v-list>
    </v-menu>
    <input-dialog
      :show="zip"
      :title="$t('serviceProviders.filters.zip')"
      @input="add('zip', $event)"
      @show="show('zip', $event)"
    />
    <input-dialog
      :show="city"
      :title="$t('serviceProviders.filters.city')"
      @input="add('city', $event)"
      @show="show('city', $event)"
    />
    <select-dialog
      :show="country"
      :title="$t('serviceProviders.filters.country')"
      :label="$t('serviceProviders.filters.country')"
      :items="countries"
      @input="add('country', $event)"
      @show="show('country', $event)"
    />
    <input-dialog
      :show="province"
      :title="$t('serviceProviders.filters.province')"
      @input="add('province', $event)"
      @show="show('province', $event)"
    />
    <select-dialog
      :show="status"
      :items="statusList"
      :title="$t('serviceProviders.filters.status')"
      :label="$t('serviceProviders.filters.status')"
      @input="add('status', $event)"
      @show="show('status', $event)"
    />
    <input-dialog
      :show="visibility"
      :title="$t('serviceProviders.filters.visibility')"
      @input="add('visibility', $event)"
      @show="show('visibility', $event)"
    />
    <select-dialog
      :show="type"
      :items="types"
      item-text="name"
      item-value="uid"
      :title="$t('serviceProviders.filters.type')"
      :label="$t('serviceProviders.filters.type')"
      @input="add('type', $event)"
      @show="show('type', $event)"
    />
    <input-dialog
      :show="typeCount"
      :title="$t('serviceProviders.filters.typeCount')"
      @input="add('typeCount', $event)"
      @show="show('typeCount', $event)"
      type="number"
    />
  </div>
</template>

<script>
import InputDialog from "@/components/shared/InputDialog";
import SelectDialog from "@/components/shared/SelectDialog";

export default {
  props: {
    value: { type: Object, required: true }
  },
  data() {
    return {
      filters: this.value,
      zip: false,
      city: false,
      country: false,
      province: false,
      status: false,
      visibility: false,
      type: false,
      typeCount: false,
      countries: [
        { text: this.$t("countries.AT"), value: "AT" },
        { text: this.$t("countries.DE"), value: "DE" }
      ],
      types: null,
      statusList: [
        { text: this.$t("subscriptions.status.PENDING"), value: "PENDING" },
        { text: this.$t("subscriptions.status.ACTIVE"), value: "ACTIVE" },
        { text: this.$t("subscriptions.status.ON_HOLD"), value: "ON_HOLD" },
        { text: this.$t("subscriptions.status.CANCELLED"), value: "CANCELLED" },
        { text: this.$t("subscriptions.status.SWITCHED"), value: "SWITCHED" },
        { text: this.$t("subscriptions.status.EXPIRED"), value: "EXPIRED" },
        {
          text: this.$t("subscriptions.status.PENDING_CANCEL"),
          value: "PENDING_CANCEL"
        }
      ]
    };
  },
  computed: {
    chips() {
      return Object.keys(this.filters)
        .map(k => {
          if (!this.filters[k]) return [];

          if (!Array.isArray(this.filters[k])) {
            this.$delete(this.filters, k);
            return [];
          }

          return this.filters[k].map((v, i) => {
            const that = this;
            return {
              key: k,
              value: v,
              index: i,
              remove: () => {
                that.filters[k].splice(i, 1);
                if (!that.filters[k] || that.filters[k].length < 1) {
                  that.$delete(that.filters, k);
                }
              }
            };
          });
        })
        .flat();
    }
  },
  created() {
    this.$store.dispatch("serviceProviders/types").then(t => (this.types = t));
  },
  components: {
    InputDialog,
    SelectDialog
  },
  methods: {
    show(field, value) {
      this.$set(this, field, value);
    },
    add(field, val) {
      if (!this.filters[field]) {
        this.$set(this.filters, field, [val]);
      } else {
        this.filters[field].push(val);
      }
      this.show(field, false);
    },
    typeItem(uid) {
      return !this.types ? {} : this.types.find(t => t.uid === uid);
    }
  }
};
</script>

<style></style>
